import ResponsiveDrawer from "./ResponseiveDrawer";
import { useEffect, useReducer, useRef } from "react";
import { pages } from "./pages";
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import LoginPage from "./LoginPage";
import { appAction, appInitialArgs, appReducer, checkAuth, defaultTheme, modalComponent, queryLoginCode, themes } from "./helpers";
import { Modal } from "@mui/material";
import LoadingPage from "./LoadingPage";

function App() {

  const [appState, dispatch] = useReducer(appReducer, appInitialArgs);
  const appStateRef = useRef(appState);
  appStateRef.current = appState

  const CurrentPage = pages[appState.currentPage].page;
  const loggedIn = appState.loggedIn;

  const pagesArray = Object.values(pages)
  const groupNames = [...new Set(pagesArray.map(obj => obj.group))].sort();
  const groups = groupNames.map(group => pagesArray.filter(obj => obj.group === group));

  const ModalComponent = appState.modalType != null ? modalComponent[appState.modalType] : null


  const theme = themes[appState?.user?.theme ?? defaultTheme];

  useEffect(() => {
    if (loggedIn === null) {
      checkAuth(dispatch)
    } else if (loggedIn && queryLoginCode()) {
      window.history.replaceState(null, '', window.location.origin);
    }
  }, [loggedIn])

  useEffect(() => {
    const popStateEvent = ({ state }) => {
      dispatch({ type: appAction.popState, state })
    }
    window.addEventListener('popstate', popStateEvent);
    return () => {
      window.removeEventListener('popstate', popStateEvent)
    };
  }, [dispatch])

  return (
    <ThemeProvider theme={theme.theme}>
      <CssBaseline />
      {appState.loggedIn === null &&
        <LoadingPage />
      }
      {appState.loggedIn === false &&
        <LoginPage appState={appState} dispatch={dispatch} />
      }
      {appState.loggedIn &&
        <div className="App" style={{ height: '100lvh', maxWidth: '1000px' }}>
          <ResponsiveDrawer
            groups={groups}
            appState={appState}
            dispatch={dispatch}
          >
            <CurrentPage appState={appState} dispatch={dispatch} />
          </ResponsiveDrawer>
        </div>
      }
      <Modal
        open={appState.modalItem != null}
        onClose={() => { window.history.go(-1) }}
        sx={{
          overflow: 'scroll',
        }}
      >
        <>{/* Need this fragment, react is mad about refs*/}
          {appState.modalItem && ModalComponent &&
            <ModalComponent item={appState.modalItem} dispatch={dispatch} />
          }
        </>
      </Modal>
    </ThemeProvider>
  );
}



export default App;

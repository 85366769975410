import { Box, Card, CardContent, CardMedia, LinearProgress, List, ListItem, ListItemButton, ListItemText, Popover, Stack, Typography } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { plexbotColors, prettyDateTime, titleStatus } from "./helpers";
import { STRING_TITLE_HAVE } from "./strings";
import { useRef, useState } from "react";


const ListTitle = ({ title }) => {
    return (
        <Card sx={{ display: 'flex', position: 'relative', userSelect: 'none' }}>
            <TitleCommon title={title} bannerSize={80} />
        </Card>
    )
}

export const TitleBanner = ({ title, size }) => {
    const status = titleStatus(title)
    const color = (() => {
        if (status === 'HAVE') {
            return plexbotColors.have
        } else if (status === 'ERROR') {
            return plexbotColors.error
        } else {
            return plexbotColors.pending
        }
    })()
    return (
        status !== 'NONE' &&
        <div style={{
            backgroundColor: color,
            position: 'absolute',
            width: `${size}px`,
            height: `${size}px`,
            top: `${size * -0.5}px`,
            right: `${size * -0.5}px`,
            transform: 'rotate(45deg)',
            zIndex: 10,
        }} />
    )
}

export const TitleCommon = ({ title, bannerSize, popMenu = false }) => {
    const status = titleStatus(title)
    const [popOpen, setPopOpen] = useState(false);
    const elRef = useRef();
    const titleName = `${title.name} (${title.year})`;
    const imdbUrl = `https://www.imdb.com/title/${title.imdb_id}/`;
    const googleUrl = `https://www.google.com/search?q=${titleName}`;
    const justWatchUrl = `https://www.justwatch.com/us/search?q=${titleName}`;
    return (
        <div style={{ display: 'flex' }}>
            <TitleBanner title={title} size={bannerSize} />
            <CardMedia
                component="img"
                sx={{ width: 100, minHeight: 160 }}
                image={title.image_url_s ?? '/noimg.png'}
                alt={title.name}
                onClick={() => setPopOpen(true)}
                ref={elRef}
            />
            {popMenu &&
                <Popover
                    open={popOpen}
                    anchorEl={elRef.current}
                    onClose={() => { setPopOpen(false) }}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                >
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton
                                href={imdbUrl}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setPopOpen(false)
                                    window.open(imdbUrl, '_blank');
                                }}
                            >
                                <ListItemText primary="IMDB Page" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                href={googleUrl}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setPopOpen(false)
                                    window.open(googleUrl, '_blank');
                                }}
                            >
                                <ListItemText primary="Google Search" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                href={justWatchUrl}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setPopOpen(false)
                                    window.open(justWatchUrl, '_blank');
                                }}
                            >
                                <ListItemText primary="JustWatch Search" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                onClick={(e) => {
                                    setPopOpen(false)
                                    navigator.clipboard.writeText(titleName)
                                }}
                            >
                                <ListItemText primary="Copy Name" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                onClick={(e) => {
                                    setPopOpen(false)
                                    navigator.clipboard.writeText(title.imdb_id)
                                }}
                            >
                                <ListItemText primary="Copy IMDB ID" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Popover>
            }
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <CardContent sx={{ flex: '1 0 auto', display: 'flex', flexDirection: 'column' }}>
                    <Stack alignItems="center" direction="row" spacing={1}>
                        {status !== 'NONE' && <CheckCircleOutlineIcon />}
                        <Typography component="div" variant="h6" >
                            {title.name}
                        </Typography>
                    </Stack>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {title.year ?? ''} {title.title_type === 'MOVIE' ? 'movie' : 'show'} - {title.certification ?? 'Not Rated'}
                    </Typography>
                    {(title.p_requesting_users ?? []).map(user => (
                        <Typography component="div" variant="subtitle2" color="text.secondary" key={user} >
                            {user}
                        </Typography>
                    ))}
                    <div style={{ flex: 1 }} />
                    {status === 'HAVE' && title.pending_info?.done_ts &&
                        <Stack alignItems="center" direction="row" gap={2}>
                            <Typography variant="">
                                Done {prettyDateTime(title.pending_info.done_ts)}
                            </Typography>
                        </Stack>}
                    {status === 'HAVE' && !title.pending_info?.done_ts &&
                        <Stack alignItems="center" direction="row" gap={2}>
                            <Typography variant="">
                                {STRING_TITLE_HAVE}
                            </Typography>
                        </Stack>}
                    {status === 'PENDING' &&
                        <Typography variant="">
                            Pending...
                        </Typography>}
                    {status === 'WAITING' &&
                        <Typography variant="">
                            Digital release: {title.pending_info.available_date ?? 'date unknown'}
                        </Typography>}
                    {status === 'DOWNLOADING' &&
                        <>
                            <Typography variant="">
                                Downloading {title.pending_info?.progress ?? 0}%
                            </Typography>
                            <LinearProgress variant="determinate" value={title.pending_info?.progress ?? 0} />
                        </>}
                    {status === 'ERROR' &&
                        <Typography variant="">
                            Error
                        </Typography>}
                </CardContent>
            </Box>
        </div>
    )
}

export default ListTitle;
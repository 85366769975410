import SearchBox from "./SearchBox";
import { appAction, modalType } from "./helpers";
import { CircularProgress, Stack } from "@mui/material";
import ListTitle from "./ListTitle";
import { STRING_SEARCH_NO_RESULTS } from "./strings";

const SearchPage = ({ appState, dispatch }) => {
    const searching = appState.searching;

    return (
        <div>
            <SearchBox
                appState={appState}
                dispatch={dispatch}
            />
            {searching &&
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                    <CircularProgress />
                </div>
            }
            {appState.searchResults !== null && (appState.searchResults?.titles?.length ?? 0) === 0 &&
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                    {appState.searchResults?.message ?? STRING_SEARCH_NO_RESULTS}
                </div>
            }
            <Stack direction="column" spacing={2} marginTop={2}>
                {(appState.searchResults?.titles ?? []).map(title => (
                    <div
                        style={{ cursor: 'pointer' }}
                        key={title.imdb_id}
                        onClick={() => {
                            dispatch({ type: appAction.update, value: { modalItem: title, modalType: modalType.title } })
                        }}
                    >
                        <ListTitle key={title.imdb_id} title={title} />
                    </div>
                ))}
            </Stack>
        </div>
    )
}

export default SearchPage;
import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { appAction, fetchWithRetry } from './helpers';
import { pages } from './pages';

function SearchBox({ appState, dispatch }) {
  const searchTerm = appState.searchTerm;
  const searchDisabled = searchTerm.trim() === '' || appState.searching;
  const setSearchTerm = (searchTerm) => { dispatch({ type: appAction.update, value: { searchTerm } }) }

  const handleSearch = () => {
    if (appState.previousSearchTerm !== appState.searchTerm) {
      // New search term, do search
      dispatch({
        type: appAction.update,
        value: {
          searching: true,
          searchResults: null,
          previousSearchTerm: searchTerm,
          currentPage: pages.search.id,
        }
      })
      fetchWithRetry(`/api/search?query=${searchTerm}`)
        .then(response => {
          if (response.ok) {
            return response.json()
          }
          throw new Error();
        })
        .then(response => {
          dispatch({ type: appAction.update, value: { searchResults: { titles: response.result } } })
        })
        .catch(() => {
          dispatch({ type: appAction.update, value: { previousSearchTerm: '', searchResults: { titles: [], message: 'something went wrong' } } })
        })
        .finally(() => {
          dispatch({ type: appAction.update, value: { searching: false } })
        })
    } else {
      // Search term has not changed, just switch to search page
      dispatch({
        type: appAction.update,
        value: {
          currentPage: pages.search.id,
        }
      })
    }
  }
  const handleKeyDown = (event) => {
    // 'Enter' key detection for search
    if (event.key === 'Enter' && !searchDisabled) {
      handleSearch();
    }
  };

  return (
    <TextField
      variant="outlined"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      onKeyDown={handleKeyDown}
      placeholder="Search..."
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleSearch} disabled={searchDisabled}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default SearchBox;

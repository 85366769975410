import { Button, Card, CardContent, FormControlLabel, FormGroup, Stack, Switch, TextField } from "@mui/material";
import { useState } from "react";
import { appAction, deleteUser, postUser } from "./helpers";

const userFields = [
    ['email', 'email', 'text_no_update'],
    ['name', 'Name', 'text'],
    ['active', 'Active', 'bool'],
    ['admin', 'Admin', 'bool'],
]

const ModalUser = ({ item, dispatch }) => {
    const user = item;
    // User but only with fields in userFields list
    const userWithFields = userFields.map(u => u[0]).reduce((newUser, key) => key in user ? { ...newUser, [key]: user[key] } : newUser, {});
    const [userData, setUserData] = useState(userWithFields);
    return (
        <Card sx={titleLargeCardStyle}>
            <CardContent>
                <Stack direction="column" spacing={2}>
                    <Button
                        disabled={user.email == null}
                        variant="contained"
                        onClick={() => {
                            sessionStorage.setItem('viewAs', user.email)
                            dispatch({ type: appAction.logout })
                        }}
                    >
                        View As
                    </Button>
                    {userFields.map(([field, label, fieldType]) => {
                        if (fieldType === 'text' || fieldType === 'text_no_update') {
                            return (
                                <TextField
                                    disabled={fieldType === 'text_no_update' && user[field] != null}
                                    key={field}
                                    label={label}
                                    value={userData[field] ?? ''}
                                    onChange={e => setUserData(u => ({ ...u, [field]: e.target.value }))}
                                />
                            )
                        } else if (fieldType === 'bool') {
                            return (
                                <FormGroup key={field}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={userData[field] ?? false}
                                                onChange={e => setUserData(u => ({ ...u, [field]: e.target.checked }))}
                                            />
                                        }
                                        label={label}
                                    />
                                </FormGroup>
                            )
                        }
                        return null
                    })}
                    <Button
                        variant="contained"
                        onClick={() => {
                            postUser(userData, dispatch)
                                .then(() => {
                                    window.history.go(-1)
                                })
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            const result = window.confirm(`Really delete ${user.email}?`);
                            if (!result) {
                                return
                            }
                            deleteUser(userData, dispatch)
                                .then(() => {
                                    window.history.go(-1)
                                })
                        }}
                    >
                        Delete User
                    </Button>
                </Stack>
            </CardContent>
        </Card>
    )
}


const titleLargeCardStyle = {
    position: 'absolute',
    top: '50px',
    left: '50%',
    transform: 'translate(-50%, 0)',
    maxWidth: '85vw',
    width: '500px',
    boxShadow: 24,
}

export default ModalUser;
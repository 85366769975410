import SearchIcon from '@mui/icons-material/Search';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PersonIcon from '@mui/icons-material/Person';
import SearchPage from './SearchPage';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AdminPageUsers from './AdminPageUsers';
import { DownloadsPage, WatchingPage } from './DownloadsPage';
import UserPage from './UserPage';
import HomePage from './HomePage';
import AdminPageEvents from './AdminPageEvents';


export const pages = {
  home: {
    id: 'home',
    label: 'Home',
    icon: <HomeIcon />,
    page: HomePage,
    group: 1,
    shortcut: false,
  },
  search: {
    id: 'search',
    label: 'Search',
    icon: <SearchIcon />,
    page: SearchPage,
    group: 1,
    shortcut: true,
  },
  downloads: {
    id: 'downloads',
    label: 'Downloads',
    icon: <CloudDownloadIcon />,
    page: DownloadsPage,
    group: 1,
    shortcut: true,
  },
  upcoming: {
    id: 'upcoming',
    label: 'Upcoming',
    icon: <ScheduleIcon />,
    page: WatchingPage,
    group: 1,
    shortcut: true,
  },
  user: {
    id: 'user',
    label: 'User',
    label_fn: (state) => state?.user?.name ?? 'User',
    icon: <PersonIcon />,
    page: UserPage,
    group: 2,
    shortcut: true,
  },
  admin: {
    id: 'admin',
    label: 'Users',
    admin: true,
    icon: <PeopleIcon />,
    page: AdminPageUsers,
    group: 2,
    shortcut: false,
  },
  adminEvents: {
    id: 'adminEvents',
    label: 'Events',
    admin: true,
    icon: <EventNoteIcon />,
    page: AdminPageEvents,
    group: 2,
    shortcut: true,
  },
}

import { Alert, Stack } from "@mui/material";
import SearchBox from "./SearchBox";
import { pages } from "./pages";
import { appAction } from "./helpers";

const HomePage = ({ appState, dispatch }) => {
    const homeLinks = Object.values(pages).filter(page => page.shortcut && (!page.admin || appState.user.admin))
    return (
        <Stack direction="column" spacing={2}>
        {(appState.messages ?? []).map((message, i) => (
            <Alert severity={message.type} key={i}>
                {message.text}
            </Alert>
        ))}
        <img src="/plexbot.svg" height="64px" alt="Plexbot" />
            <SearchBox appState={appState} dispatch={dispatch} />
            <div style={{ display: 'flex', flexDirection: 'row' }} >
                {homeLinks.map(page => (
                    <div
                        key={page.id}
                        style={{ flex: 1, textAlign: 'center' }}
                    >
                        <div
                            style={{ cursor: 'pointer', display: 'inline-block', paddingInline: '10px' }}
                            onClick={() => {
                                dispatch({ type: appAction.update, value: { currentPage: page.id } })
                            }}
                        >
                            {page.icon}
                        </div>
                    </div>
                ))}
            </div>
        </Stack>
    )
}


export default HomePage;
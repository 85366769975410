import { Button, Card, CardActions, CardContent } from "@mui/material"
import { appAction } from "./helpers"


const ModalMessage = ({ item, dispatch }) => {
    return (
        <Card sx={cardStyle}>
            <CardContent>
                {item.body}
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
                {
                    item.buttons.map(button => (
                        <Button
                            size="small"
                            key={button.label}
                            {...button.props}
                            onClick={() => {
                                dispatch({ type: appAction.message, message: null })
                                if (button.action) {
                                    button.action()
                                }
                            }}
                        >
                            {button.label}
                        </Button>
                    ))
                }
            </CardActions>
        </Card>
    )
}



const cardStyle = {
    position: 'absolute',
    top: '50px',
    left: '50%',
    transform: 'translate(-50%, 0)',
    maxWidth: '85vw',
    width: '500px',
    boxShadow: 24,
}

export default ModalMessage;
import { createTheme } from "@mui/material"
import ModalTitle from "./ModalTitle"
import ModalUser from "./ModalUser"
import ModalMessage from "./ModalMessage"

export const appAction = {
    update: 'update',
    selectTitle: 'selectTitle',
    updateSearchTitle: 'updateSearchTitle',
    logout: 'logout',
    message: 'message',
    popState: 'popState',
}

export const loginResult = {
    failed: 'failed',
    uuidFail: 'uuidFail',
    success: 'success',
}

export const plexbotColors = {
    have: '#00ff00',
    pending: '#50c0c0',
    error: '#c02020',
}

export const instructionType = {
    addTitle: 'add_title'
}

/*export function prettyDateTime(ts, withYear=false) {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const date = new Date(ts * 1000); // Convert to milliseconds
    const dayOfWeek = days[date.getDay()];
    const year = date.getFullYear().toString().slice(-2)
    const month = (date.getMonth() + 1).toString()//.padStart(2, '0');
    const day = date.getDate().toString()//.padStart(2, '0');
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours.toString()//.padStart(2, '0');

    if (withYear) {
        return `${dayOfWeek} ${month}/${day}/${year} ${hours}:${minutes}${ampm}`;
    }
    return `${dayOfWeek} ${month}/${day} ${hours}:${minutes}${ampm}`;
}*/

export function prettyDateTime(timestamp, withYear=false) {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
    const date = new Date(timestamp * 1000); // Convert Unix timestamp from seconds to milliseconds
    const dayOfWeek = days[date.getDay()];
    const month = months[date.getMonth()];
    const dayOfMonth = date.getDate();
    const year = date.getFullYear().toString()//.slice(-2); // Get last two digits of year
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? 'pm' : 'am';
    const formattedHours = ((hours + 11) % 12 + 1); // Convert 24h to 12h format
    const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    if (withYear) {
        return `${dayOfWeek} ${month} ${dayOfMonth}, ${year}`;
    }
    return `${dayOfWeek} ${month} ${dayOfMonth} ${formattedHours}:${paddedMinutes}${period}`;
}

export function titleStatus(title) {
    if (title.x_status === 'DELETED') {
        return 'NONE'
    }
    if (title.x_status !== 'HAVE' && 'pending_info' in title) {
        return title.pending_info.status;
    }
    return title.x_status;
}

export function titleStatusDesc(title) {
    const status = titleStatus(title);

    return ({
        HAVE: 'Title is on Plex!',
        NONE: null,
        DOWNLOADING: 'Downloading',
        WAITING: 'Waiting for release',
        PENDING: 'Plexbot is working on it',
    })[status]
}

function addHeaders(init, obj) {
    return { ...init, headers: { ...(init?.headers ?? {}), ...obj } }
}

export async function fetchWithRetry(input, init, maxRetries = 2) {
    init = addHeaders(init, viewAsHeaders())

    let response;
    for (let i = 0; i < maxRetries; i++) {
        response = await fetch(input, init);
        if (response.status !== 401) {
            break;
        }
        await new Promise(resolve => setTimeout(resolve, 1000));
    }
    return response;
}

export function addTitle(imdbId) {
    return fetchWithRetry('/api/instruction', {
        method: 'POST',
        headers: {
            Accepts: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            type: instructionType.addTitle,
            imdb_id: imdbId
        })
    })
}

export function appReducer(state, action) {
    if (action.type === appAction.update) {
        const newState = { ...state, ...action.value };
        if ('currentPage' in action.value || 'modalItem' in action.value) {
            window.history.pushState({
                currentPage: newState.currentPage,
                modalItem: newState.modalItem,
                modalType: newState.modalType,
            }, '')
        }
        return { ...state, ...action.value };
    }

    if (action.type === appAction.updateSearchTitle) {
        if (state.searchResults?.titles == null) return state;
        return {
            ...state, searchResults: {
                ...state.searchResults, titles: state.searchResults?.titles.map(t => (
                    t.imdb_id === action.title.imdb_id ? action.title : t))
            }
        }
    }
    if (action.type === appAction.logout) {
        return { ...appInitialArgs }
    }
    if (action.type === appAction.popState) {
        if (action.state === null) {
            return { ...state, currentPage: 'home', modalItem: null, modalType: null }
        }
        return { ...state, ...action.state }
    }
    if (action.type === appAction.message) {
        if (action.message) {
            return { ...state, modalItem: action.message, modalType: modalType.message }
        } else if (state.modalType === modalType.message) {
            return { ...state, modalItem: null, modalType: null }
        }
    }
    return state;
}

export const appInitialArgs = {
    loggedIn: null,
    searchTerm: '',
    previousSearchTerm: '',
    searchResults: null,
    titlesDownloading: null,
    titlesWatching: null,
    currentPage: 'home',
    user: {},
    searching: false,
    modalItem: null,
    modalType: null,
    messages: [],
}

export const modalType = {
    title: 'title',
    user: 'user',
    message: 'message',
}

export const modalComponent = {
    [modalType.title]: ModalTitle,
    [modalType.user]: ModalUser,
    [modalType.message]: ModalMessage,

}

export const submitCode = (code, dispatch, ignoreUuid = false) => {
    const request = fetch('/api/auth/login_code', {
        method: 'POST',
        headers: {
            Accepts: 'applicate/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ login_code: code, ignore_uuid: ignoreUuid })
    })
    return checkLoggedIn(request)
        .then(response => {
            if (response.result === loginResult.ignoreUuid) {
                // User requested that uuid be ignored, retry the login with ignoreUuid = true
                return submitCode(code, dispatch, true)
            } else {
                // Login was successful or failed
                dispatch({
                    type: appAction.update, value: {
                        loggedIn: response.loggedIn,
                        user: response.user
                    }
                })
                return response;
            }
        })
}

export const checkLoggedIn = (request) => {
    return request.then(response => {
        if (response.ok) {
            return response.json();
        } else if (response.status === 403) {
            throw new Error(loginResult.uuidFail);
        } else {
            throw new Error(loginResult.failed);
        }
    })
        .then(response => {
            return {
                result: loginResult.success,
                loggedIn: true,
                ...response
            }
        })
        .catch((error) => {
            if (sessionStorage.getItem('viewAs')) {
                sessionStorage.removeItem('viewAs');
            }
            return {
                result: error.message,
                loggedIn: false,
                user: {},
            }
        })
}

const viewAsHeaders = () => {
    const viewAs = sessionStorage.getItem('viewAs')
    return viewAs ? { viewAs } : {}
}

export const checkAuth = (dispatch) => {
    const request = fetch('/api/userauth', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            ...viewAsHeaders(),
        }
    })
    checkLoggedIn(request)
        .then(response => {
            dispatch({
                type: appAction.update, value: {
                    loggedIn: response.loggedIn,
                    user: response.user,
                    messages: response.messages,
                }
            })
        })
}

export const logout = (dispatch, everywhere = false) => {
    if (sessionStorage.getItem('viewAs')) {
        sessionStorage.removeItem('viewAs');
        dispatch({ type: appAction.logout });
        return;
    }
    const body = {}
    if (everywhere) {
        body.everywhere = '1';
    }
    const request = fetch('/api/logout', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    })
    checkLoggedIn(request)
        .then(() => {
            dispatch({ type: appAction.logout })
        })
}

export const getUserList = (dispatch) => {
    return fetchWithRetry('/api/admin/user', {
        headers: {
            'Accept': 'application/json'
        }
    })
        .then(response => response.json())
        // catch error here
        .then(({ users }) => {
            users.sort((a, b) => a.name.localeCompare(b.name))
            dispatch({ type: appAction.update, value: { userList: users } })
        })
}

export const getEvents = (dispatch) => {
    return fetchWithRetry('/api/admin/events', {
        headers: {
            'Accept': 'application/json'
        }
    })
        .then(response => response.json())
        // catch error here
        .then(({ events }) => {
            dispatch({ type: appAction.update, value: { events } })
        })
}

export const postUser = (user, dispatch) => {
    return fetchWithRetry('/api/admin/user', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user })
    })
        .then(response => response.json())
        .then(({ users }) => {
            users.sort((a, b) => a.name.localeCompare(b.name))
            dispatch({ type: appAction.update, value: { userList: users } })
        })
}

export const deleteUser = (user, dispatch) => {
    return fetchWithRetry('/api/admin/user', {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user })
    })
        .then(response => response.json())
        .then(({ users }) => {
            users.sort((a, b) => a.name.localeCompare(b.name))
            dispatch({ type: appAction.update, value: { userList: users } })
        })
}

export const SvgCloseIcon = () => {
    return (
        <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="45" fill="black" fillOpacity="0.5" />
            <line x1="30" y1="30" x2="70" y2="70" stroke="white" strokeWidth="10" />
            <line x1="70" y1="30" x2="30" y2="70" stroke="white" strokeWidth="10" />
        </svg>
    )
}

export const themes = {
    dark: {
        name: 'Dark',
        default: true,
        theme: createTheme({
            palette: {
                mode: 'dark',
            },
        })
    },
    light: {
        name: 'Light',
        theme: createTheme({
            palette: {
                mode: 'light',
            },
        })
    },
}

export const queryLoginCode = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return params.get("login");
}

export const defaultTheme = Object.entries(themes).filter(([k, v]) => v.default).map(([k, v]) => k)[0]
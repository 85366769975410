export const STRING_SHOW_INFO = "Plexbot will look for past and future episodes for shows, but these won't appear on the downloads page."
export const STRING_TITLE_ADD = "Add to Plex"
export const STRING_TITLE_HAVE = "Title is on Plex!"
export const STRING_PROGRESS_NO_RESULTS = "There's nothing here!"
export const STRING_UUID_WARNING = "It appears that you are trying to log in with a different browser than you started. If you clicked a login link in your email client, please use the menu to open this page in your normal browser so that you can stay logged in."
export const STRING_UUID_BUTTON_LOGIN = "Login anyway"
export const STRING_UUID_BUTTON_CONTINUE = "Continue"
export const STRING_UUID_MENU_INSTRUCTION = "Use your email client menu to open this page in your browser."
export const STRING_LOGIN_FAILED = "Login failed. Your link may have expired."
export const STRING_LOGIN_INSTRUCTION = "You will receive a login code shortly. You do not need to re-enter your email when submitting your login code."
export const STRING_LOGIN_LINK_MESSAGE = "logging you in..."
export const STRING_LOGOUT_BUTTON = "Log Out"
export const STRING_LOGOUT_EVERYWHERE_BUTTON = "Log Out Everywhere"
export const STRING_SEARCH_NO_RESULTS = "no results"
export const STRING_TITLE_ERROR = "Sorry, there was a problem with this title. If this is a future title, it might be that it exists on IMDb but not yet on tmdb (for movies) or tvdb (for shows). Please try adding it again later on. If this is an old title, please contact support."
import { Button, Paper, Stack, Typography } from "@mui/material";
import { getEvents } from "./helpers";
import { useEffect, useState } from "react";


const AdminPageEvents = ({ appState, dispatch }) => {
    useEffect(() => {
        getEvents(dispatch);
    }, [dispatch])

    return (
        <Stack direction="column" spacing={2}>
            <EventList appState={appState} dispatch={dispatch} />
        </Stack>
    )
}

const EventList = ({ appState, dispatch }) => {
    const [loadEvents, setLoadEvents] = useState(false);
    const userObj = (appState.userList ?? []).reduce((users, user) => ({ ...users, [user.email]: user }), {})

    return (
        <>
        <Typography variant="subtitle2">
            Build:<br />
            {process.env.REACT_APP_BUILD_TIME}
        </Typography>
            <Button
                variant="outlined"
                disabled={loadEvents}
                onClick={() => {
                    setLoadEvents(true);
                    getEvents(dispatch)
                        .finally(() => { setLoadEvents(false) })
                }}
            >
                Reload Events
            </Button>
            <Stack spacing={1} direction={"column"} >
                {(appState.events ?? []).slice(0, 100).map(event => (
                    <Paper
                        key={event.sk}
                        sx={{ p: .5 }}
                        onClick={() => {
                            window.alert(JSON.stringify(event, null, 2))
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography variant="subtitle2" color="text.secondary">
                                {event.event_type}
                            </Typography>
                            <div style={{ flex: 1 }} />
                            <Typography variant="subtitle2" color="text.secondary">
                                {formatTs(event.sk)}
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography variant="body" >
                                {userObj[event.user]?.name ?? event.user ?? event.ip}
                            </Typography>
                            <div style={{ flex: 1 }} />
                            <Typography variant="body" sx={{ flex: '0 1 auto', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '200px' }} >
                                {event.comment}
                            </Typography>
                        </div>
                    </Paper>
                ))}
            </Stack>
        </>
    )
}

const formatTs = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000); // Convert Unix timestamp to milliseconds
    const hours = date.getHours();
    const minutes = "0" + date.getMinutes();
    const month = "0" + (date.getMonth() + 1);
    const day = "0" + date.getDate();

    // Convert 24h to 12h format and set am/pm
    const hours12hFormat = hours % 12 || 12; // Convert 0 to 12 for 12 AM
    const ampm = hours < 12 ? "a" : "p";

    // Format the date as MM/DD HH:MM AM/PM
    const formattedDate = `${month.slice(-2)}/${day.slice(-2)} ${hours12hFormat}:${minutes.slice(-2)}${ampm}`;

    return formattedDate;
}

export default AdminPageEvents;
import { Alert, Button, Card, CardContent, Stack, Typography } from "@mui/material";
import { SvgCloseIcon, addTitle, appAction, prettyDateTime, titleStatus, titleStatusDesc } from "./helpers";
import { pages } from "./pages";
import { useState } from "react";
import { TitleCommon } from "./ListTitle";
import { STRING_SHOW_INFO, STRING_TITLE_ADD, STRING_TITLE_ERROR } from "./strings";

const ModalTitle = ({ item, dispatch }) => {
    const title = item;
    const status = titleStatus(title);
    const statusDesc = titleStatusDesc(title);
    const [working, setWorking] = useState(false);
    return (
        <Card sx={titleLargeCardStyle}>
            <TitleLargeExitButton onClick={() => { window.history.go(-1) }} />
            <TitleCommon title={title} bannerSize={110} popMenu />
            <CardContent>
                <Stack direction="column" spacing={2}>
                    <Button
                        variant="contained"
                        disabled={status !== 'NONE' || working}
                        onClick={() => {
                            setWorking(true)
                            addTitle(title.imdb_id)
                                .then(response => {
                                    if (response.ok) {
                                        return response.json()
                                    } else {
                                        throw new Error()
                                    }
                                })
                                .then(response => {
                                    dispatch({ type: appAction.updateSearchTitle, title: response.title })
                                    dispatch({ type: appAction.update, value: { currentPage: pages.downloads.id, selectedTitle: null, modalItem: null, modalType: null } })
                                })
                                .catch(() => {
                                    window.alert('Sorry, something went wrong!')
                                })
                                .finally(() => {
                                    setWorking(false)
                                })
                        }}
                    >
                        {statusDesc || STRING_TITLE_ADD}
                    </Button>
                    {title.pending_info?.status === 'ERROR' &&
                        <Alert severity="error">
                            {STRING_TITLE_ERROR}
                        </Alert>
                    }
                    {title.title_type === 'SHOW' &&
                        <Alert severity="info">
                            {STRING_SHOW_INFO}
                        </Alert>
                    }
                    {title.x_status === 'HAVE' && title.x_complete_time &&
                        <Typography variant="subtitle2" color="text.secondary">
                            Added {prettyDateTime(title.x_complete_time, true)}
                        </Typography>
                    }
                    {title.rating &&
                        <Typography variant="body1">
                            {title.rating ?? '?'} / 10
                        </Typography>
                    }
                    {title.runtime &&
                        <Typography variant="body1">
                            {title.runtime} minutes
                        </Typography>
                    }
                    <Typography variant="body2">
                        {title.description}
                    </Typography>
                </Stack></CardContent>
        </Card>
    )
}
const TitleLargeExitButton = ({ onClick }) => {
    return (
        <div
            style={{
                position: 'absolute',
                width: '30px',
                height: '30px',
                top: 5,
                right: 5,
                zIndex: 20,
                cursor: 'pointer',
            }}
            onClick={onClick}
        >
            <SvgCloseIcon />
        </div>
    )
}
const titleLargeCardStyle = {
    position: 'absolute',
    top: '50px',
    left: '50%',
    transform: 'translate(-50%, 0)',
    maxWidth: '85vw',
    width: '500px',
    boxShadow: 24,
}

export default ModalTitle;

import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Container, Snackbar, Stack, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { appAction, loginResult, submitCode, queryLoginCode } from './helpers';
import { STRING_LOGIN_FAILED, STRING_LOGIN_INSTRUCTION, STRING_LOGIN_LINK_MESSAGE, STRING_UUID_BUTTON_CONTINUE, STRING_UUID_BUTTON_LOGIN, STRING_UUID_MENU_INSTRUCTION, STRING_UUID_WARNING } from './strings';

const LoginPage = ({ appState, dispatch }) => {

    const [loginCode, setLoginCode] = useState(queryLoginCode());
    const [msgOpen, setMsgOpen] = useState(false);
    const [message, setMessage] = useState(queryLoginCode() ? STRING_LOGIN_LINK_MESSAGE : null)

    const submitEmail = (email) => {
        if (email === '' || email === appState.lastLoginEmail) {
            setMsgOpen(true);
            return;
        }
        dispatch({ type: appAction.update, value: { lastLoginEmail: email } })
        fetch('/api/auth/request', {
            method: 'POST',
            headers: {
                Accepts: 'applicate/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email })
        })
            .then(() => {
                setMsgOpen(true);
            })
    }

    useEffect(() => {
        if (loginCode) {
            loginFlow(loginCode, dispatch, setMessage, setLoginCode);
        }
    }, [loginCode, dispatch])

    return (
        <>
            <Container style={{ height: '100vh', display: 'flex', flexDirection: 'column', maxWidth: '800px'}}>


                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                    <img src="/plexbot.svg" height="128px" alt="plexbot.net" />
                </div>
                <Stack direction="column" spacing={2} padding={2} alignItems={'center'}>
                    {message &&
                        <Typography variant="body1">
                            {message}
                        </Typography>
                    }
                    {loginCode == null &&
                        <>
                            <LoginBox placeholder="Email Address" onSubmit={submitEmail} />
                            <LoginBox placeholder="Login Code" onSubmit={(code) => {
                                loginFlow(code, dispatch, setMessage, setLoginCode)
                            }} upper />
                        </>
                    }
                </Stack>
                <div style={{ flex: 1 }} />
            </Container>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={msgOpen}
                onClose={() => { setMsgOpen(false) }}
                message={STRING_LOGIN_INSTRUCTION}
            />
        </>
    )
}

function LoginBox({ placeholder, onSubmit, upper = false }) {
    const [text, setText] = useState('')

    const handleKeyDown = (event) => {
        // 'Enter' key detection for search
        if (event.key === 'Enter') {
            onSubmit(text);
        }
    };

    return (
        <TextField
            variant="outlined"
            value={text}
            onChange={(e) => setText(upper ? e.target.value.toUpperCase() : e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            fullWidth
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={() => { onSubmit(text) }}>
                            <SendIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
}

const loginFlow = (loginCode, dispatch, setMessage, setLoginCode) => {
    const uuidErrorMessage = {
        body: STRING_UUID_WARNING,
        buttons: [
            {
                label: STRING_UUID_BUTTON_LOGIN,
                props: {
                    color: 'error',
                    variant: 'outlined',
                },
                action: () => {
                    submitCode(loginCode, dispatch, true);
                }
            },
            {
                label: STRING_UUID_BUTTON_CONTINUE,
                props: {
                    color: 'primary',
                    variant: 'contained',
                },
                action: () => {
                    setMessage(STRING_UUID_MENU_INSTRUCTION);
                }
            },
        ]
    }
    submitCode(loginCode, dispatch)
        .then(response => {
            if (response.result === loginResult.uuidFail) {
                dispatch({ type: appAction.message, message: uuidErrorMessage })
            } else if (response.result === loginResult.failed) {
                setMessage(STRING_LOGIN_FAILED);
                setLoginCode(null);
                window.history.replaceState(null, '', window.location.origin);
            } else {
                window.history.replaceState(null, '', window.location.origin);
            }
        })
}

export default LoginPage;
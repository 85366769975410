import React, { useEffect } from 'react';
import { appAction, getEvents, getUserList, modalType } from './helpers';
import { Card, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';

const AdminPageUsers = ({ appState, dispatch }) => {
    const userList = appState.userList ?? []
    useEffect(() => {
        getUserList(dispatch);
    }, [dispatch])

    return (
        <Stack direction="column" spacing={2}>
            <Card>
                <List>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={(e) => {
                                dispatch({ type: appAction.update, value: { modalItem: {active: true}, modalType: modalType.user } })
                            }}
                        >
                            <ListItemIcon>
                                <AddIcon />
                            </ListItemIcon>
                            <ListItemText primary='Add User' />
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    {userList.map(user => (
                        <ListItem disablePadding key={user.email}>
                            <ListItemButton
                                onClick={(e) => {
                                    dispatch({ type: appAction.update, value: { modalItem: user, modalType: modalType.user } })
                                }}
                            >
                                <ListItemIcon>
                                    {user.admin ? <SecurityIcon /> : <PersonIcon />}
                                </ListItemIcon>
                                <ListItemText primary={user.name} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Card>
        </Stack>
    )
}
/*
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
function TextInputWithButton({ label, buttonLabel, onClick, clearOnSubmit = false, initialText }) {
    const [text, setText] = useState(initialText ?? '');
    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <TextField
                label={label}
                variant="outlined"
                fullWidth
                value={text}
                onChange={e => { setText(e.target.value) }}
                sx={{ marginRight: 1, flexGrow: 1 }}
            />
            <Button
                variant="contained"
                sx={{ whiteSpace: 'nowrap' }}
                onClick={() => {
                    onClick(text);
                    if (clearOnSubmit) {
                        setText('')
                    }
                }}
            >
                {buttonLabel}
            </Button>
        </Box>
    );
}
*/
export default AdminPageUsers;
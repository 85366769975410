import { Button, Stack, Typography } from "@mui/material";
import { logout } from "./helpers";
import { STRING_LOGOUT_BUTTON, STRING_LOGOUT_EVERYWHERE_BUTTON } from "./strings";


const UserPage = ({ appState, dispatch }) => {

    return (
        <Stack direction="column" spacing={2} >
            <Typography variant="body1">
                {appState.user.name}
            </Typography>
            <Typography variant="body1">
                {appState.user.email}
            </Typography>
            <div style={{ height: '25px' }} />
            <Button
                variant="contained"
                onClick={() => {
                    logout(dispatch);
                }}
            >
                {STRING_LOGOUT_BUTTON}
            </Button>
            <Button
                variant="contained"
                onClick={() => {
                    logout(dispatch, true);
                }}
            >
                {STRING_LOGOUT_EVERYWHERE_BUTTON}
            </Button>
        </Stack>
    )
}

export default UserPage;